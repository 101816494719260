import React, {FC, useEffect, useState} from "react"

interface ILanguageContext {
	activeLang: string
	setActiveLang: React.SetStateAction<any>
}

const LanguageContext = React.createContext<ILanguageContext>({
	activeLang: "en",
	setActiveLang: (arg: any) => {}
})

export const LanguageContextProvider:FC = ({children}) => {
	const [activeLang, setActiveLang] = useState( "en")

	useEffect(() => {
		// @ts-ignore
		setActiveLang(localStorage.getItem('lang'))
	}, [activeLang])

	return (
		<LanguageContext.Provider value={{
			activeLang,
			setActiveLang
		}}>
			{children}
		</LanguageContext.Provider>
	)
}
export default LanguageContext
