import {createContext, FC, useEffect, useState} from "react"

interface IThemeContext {
	active?: boolean,
	toggleActive?: () => void,
	activeFactory?: boolean,
	toggleActiveFactory?: () => void,
	activeFactoryMobile?: boolean,
	toggleActiveFactoryMobile?: () => void,
	setNumber?: any,
	number?: number,
	handleLeftArrowClick?: () => void,
	handleRightArrowClick?: () => void,
	popUpScreenActive?: boolean,
	togglePopUpScreenActive?: () => void,
	setPopUpScreenActive?: any,
	width?: number,
	close?: boolean,
	toggleClose?: () => void,
	activeCyber?: boolean,
	toggleActiveCyber?: () => void,
	activeCyberMobile?: boolean,
	toggleActiveCyberMobile?: () => void,
	activeMining?: boolean,
	toggleActiveMining?: () => void,
	activeMiningMobile?: boolean,
	toggleActiveMiningMobile?: () => void,
	activeSlum?: boolean,
	toggleActiveSlum?: () => void,
	activeSlumMobile?: boolean,
	toggleActiveSlumMobile?: () => void,
	activeTech?: boolean,
	toggleActiveTech?: () => void,
	activeTechMobile?: boolean,
	toggleActiveTechMobile?: () => void,
	activeLeaw?: boolean,
	toggleActiveLeaw?: () => void,
	activeLeawMobile?: boolean,
	toggleActiveLeawMobile?: () => void,
	activeTesla?: boolean,
	toggleActiveTesla?: () => void,
	activeTeslaMobile?: boolean,
	toggleActiveTeslaMobile?: () => void,
	activeYoutube?: boolean,
	toggleActiveYoutube?: () => void,
	activeVision?: boolean,
	toggleActiveVision?: () => void,
	setActiveVision?: any,
	activeStaking?: boolean,
	toggleActiveStaking?: () => void,
	setActiveStaking?: any,
	activeTribe?: boolean,
	toggleActiveTribe?: () => void,
	activeTribeMobile?: boolean,
	toggleActiveTribeMobile?: () => void,
}

const defaultState = {
	active: true,
	activeFactory: true,
	activeFactoryMobile: true,
	number: 0,
	popUpScreenActive: true,
	width: 0,
	close: true,
	activeCyber: true,
	activeCyberMobile: true,
	activeMining: true,
	activeMiningMobile: true,
	activeSlum: true,
	activeSlumMobile: true,
	activeTech: true,
	activeTechMobile: true,
	activeLeaw: true,
	activeLeawMobile: true,
	activeTesla: true,
	activeTeslaMobile: true,
	activeYoutube: true,
	activeVision: true,
	activeStaking: true,
	activeTribe: true,
	activeTribeMobile: true,
}

const ThemeContext = createContext<IThemeContext>(defaultState)

const ThemeContextProvider:FC = ({children}) => {
	const [active, setAcrive] = useState(defaultState.active)
	const [activeCyber, setAcriveCyber] = useState(defaultState.activeCyber)
	const [activeYoutube, setActiveYoutube] = useState(defaultState.activeYoutube)
	const [activeCyberMobile, setActiveCyberMobile] = useState(defaultState.activeCyberMobile)
	const [activeTech, setActiveTech] = useState(defaultState.activeTech)
	const [activeTechMobile, setActiveTechMobile] = useState(defaultState.activeTechMobile)
	const [activeTesla, setActiveTesla] = useState(defaultState.activeTesla)
	const [activeTeslaMobile, setActiveTeslaMobile] = useState(defaultState.activeTeslaMobile)
	const [activeLeaw, setActiveLeaw] = useState(defaultState.activeLeaw)
	const [activeLeawMobile, setActiveLeawMobile] = useState(defaultState.activeLeawMobile)
	const [activeFactory, setAcriveFactory] = useState(defaultState.activeFactory)
	const [activeFactoryMobile, setActiveFactoryMobile] = useState(defaultState.activeFactoryMobile)
	const [activeMining, setActiveMining] = useState(defaultState.activeMining)
	const [activeMiningMobile, setActiveMiningMobile] = useState(defaultState.activeMiningMobile)
	const [activeSlum, setActiveSlum] = useState(defaultState.activeSlum)
	const [activeSlumMobile, setActiveSlumMobile] = useState(defaultState.activeSlumMobile)
	const [close, setClose] = useState(defaultState.close)
	const [number, setNumber] = useState(defaultState.number)
	const [popUpScreenActive, setPopUpScreenActive] = useState(defaultState.popUpScreenActive)
	const [activeVision, setActiveVision] = useState(defaultState.activeVision)
	const [activeStaking, setActiveStaking] = useState(defaultState.activeStaking)
	const [activeTribe, setActiveTribe] = useState(defaultState.activeTribe)
	const [activeTribeMobile, setActiveTribeMobile] = useState(defaultState.activeTribeMobile)

	const toggleActive = () => {
		setAcrive(!active)
	}

	const toggleActiveYoutube = () => {
		setActiveYoutube(!activeYoutube)
	}

	const toggleClose = () => {
		setClose(!close)
	}

	const togglePopUpScreenActive = () => {
		setPopUpScreenActive(!popUpScreenActive)
	}

	const toggleActiveVision = () => {
		setActiveVision(!activeVision)
	}

	const toggleActiveStaking = () => {
		setActiveStaking(!activeStaking)
	}

	const toggleActiveFactory = () => {
		setAcriveFactory(!activeFactory)
		if (activeFactory) {
			setActiveFactoryMobile(true)
		}
	}

	const toggleActiveFactoryMobile = () => {
		setActiveFactoryMobile(!activeFactoryMobile)
	}

	const toggleActiveCyber = () => {
		setAcriveCyber(!activeCyber)
		if (activeCyber) {
			setActiveCyberMobile(true)
		}
	}

	const toggleActiveCyberMobile = () => {
		setActiveCyberMobile(!activeCyberMobile)
	}

	const toggleActiveTribe = () => {
		setActiveTribe(!activeTribe)
		if (activeTribe) {
			setActiveTribeMobile(true)
		}
	}

	const toggleActiveTribeMobile = () => {
		setActiveTribeMobile(!activeTribeMobile)
	}

	const toggleActiveMining = () => {
		setActiveMining(!activeMining)
		if (activeMining) {
			setActiveMiningMobile(true)
		}
	}

	const toggleActiveMiningMobile = () => {
		setActiveMiningMobile(!activeMiningMobile)
	}

	const toggleActiveSlum = () => {
		setActiveSlum(!activeSlum)
		if (activeSlum) {
			setActiveSlumMobile(true)
		}
	}

	const toggleActiveSlumMobile = () => {
		setActiveSlumMobile(!activeSlumMobile)
	}

	const toggleActiveTech = () => {
		setActiveTech(!activeTech)
		if (activeTech) {
			setActiveTechMobile(true)
		}
	}

	const toggleActiveTechMobile = () => {
		setActiveTechMobile(!activeTechMobile)
	}

	const toggleActiveLeaw = () => {
		setActiveLeaw(!activeLeaw)
		if (activeLeaw) {
			setActiveLeawMobile(true)
		}
	}

	const toggleActiveLeawMobile = () => {
		setActiveLeawMobile(!activeLeawMobile)
	}

	const toggleActiveTesla = () => {
		setActiveTesla(!activeTesla)
		if (activeTesla) {
			setActiveTeslaMobile(true)
		}
	}

	const toggleActiveTeslaMobile = () => {
		setActiveTeslaMobile(!activeTeslaMobile)
	}

	const handleLeftArrowClick = () => {
		setNumber((i) => {
			return i - 1
		})
		if (number === 0) {
			setNumber(3)
		}
	}

	const handleRightArrowClick = () => {
		setNumber((i) => {
			return i + 1
		})
		if (number === 3) {
			setNumber(0)
		}
	}

	useEffect(() => {
		if (!activeFactory || !activeFactoryMobile || !activeCyber || !activeCyberMobile || !activeMining || !activeMiningMobile || !activeSlum || !activeSlumMobile || !activeTech || !activeTechMobile || !activeLeaw || !activeLeawMobile || !activeTesla || !activeTeslaMobile || !popUpScreenActive || !activeYoutube || !activeVision || !activeStaking || !activeTribe || !activeTribeMobile) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = ''
		}
	}, [activeFactory, activeCyber, activeMining, activeMiningMobile, activeCyberMobile, activeFactoryMobile, activeSlum, activeSlumMobile, activeTech, activeTechMobile, activeLeaw, activeLeawMobile, activeTesla, activeTeslaMobile, popUpScreenActive, activeYoutube, activeVision, activeStaking, activeTribe, activeTribeMobile])

	return (
		<ThemeContext.Provider
			value={{
				active,
				toggleActive,
				activeFactory,
				toggleActiveFactory,
				activeFactoryMobile,
				toggleActiveFactoryMobile,
				setNumber,
				number,
				handleLeftArrowClick,
				handleRightArrowClick,
				popUpScreenActive,
				togglePopUpScreenActive,
				setPopUpScreenActive,
				close,
				toggleClose,
				activeCyber,
				toggleActiveCyber,
				activeCyberMobile,
				toggleActiveCyberMobile,
				activeMining,
				toggleActiveMining,
				activeMiningMobile,
				toggleActiveMiningMobile,
				activeSlum,
				toggleActiveSlum,
				activeSlumMobile,
				toggleActiveSlumMobile,
				activeTech,
				toggleActiveTech,
				activeTechMobile,
				toggleActiveTechMobile,
				activeLeaw,
				toggleActiveLeaw,
				activeLeawMobile,
				toggleActiveLeawMobile,
				activeTesla,
				toggleActiveTesla,
				activeTeslaMobile,
				toggleActiveTeslaMobile,
				activeYoutube,
				toggleActiveYoutube,
				activeVision,
				toggleActiveVision,
				setActiveVision,
				activeStaking,
				toggleActiveStaking,
				setActiveStaking,
				activeTribe,
				activeTribeMobile,
				toggleActiveTribe,
				toggleActiveTribeMobile
			}}
		>
			{children}
		</ThemeContext.Provider>
	)
}

export default ThemeContext

export {ThemeContextProvider}
