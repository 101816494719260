/* eslint-disable @next/next/inline-script-id */
import 'styles/globals.css'
import 'tailwindcss/tailwind.css'
import type { AppProps } from 'next/app'
import { useEffect } from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import {ThemeContextProvider} from '../context/themeContext'
import {LanguageContextProvider} from '../context/languageContext'

function MyApp({ Component, pageProps }: AppProps) {
	useEffect(() => {
		smoothscroll.polyfill()

		const appHeight = () => {
			let vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty("--vh", `${vh}px`)
		}
		window.addEventListener("resize", appHeight)
		appHeight()
		return () => {
			window.removeEventListener("resize", appHeight)
		}
	}, [])
	return (
		<>
			<LanguageContextProvider>
				<ThemeContextProvider>
					<Component {...pageProps} />
				</ThemeContextProvider>
			</LanguageContextProvider>
		</>
	)
}

export default MyApp
